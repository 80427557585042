import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import TagListing from "../components/Language/TagListing";

const _ = require("lodash");

export default function LanguagesPage({ data }) {
  const languages = data.allLanguagesJson.edges;

  return (
    <Layout>
      <div className="flex flex-wrap flex-row justify-center pb-10">
        <TagListing
          category="Languages"
          allLanguagesJson={data.allLanguagesJson}
          languageEdges={languages}
          description=""
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query LanguageList {
    allLanguagesJson {
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
